<template>
<div id="contentwrap">
    <PageTitle />
    <!-- Article -->
    <article class="row">
        <div class="inner">
            <!-- Article Header -->
            <header>
                <h1 class="title">About Meredith</h1>
                <h5 class="subtitle"><span>310.923.5811</span></h5>
            </header>
            <!--/ Article Header -->
            <!-- Article content -->
            <div class="col span3of3 textleft">
                <p><br><img alt="Meredith Portrait" src="/images/about-page.jpg" class="pic-float-right-about">
                </p>
                <p>
                    Meredith approaches her business with passion, transparency, a tireless work ethic, and a tenacity that sets her apart from the rest.
                    She strives for <em><strong>real results </strong></em>for her clients - both tangible and intangible. 
                    The bottom line is critical; however, Meredith believes that so is the process and the client experience.
                </p>
                <p>
                    Over the past 11 years, Meredith has become one of the top real estate agents in Los Angeles and has <em><strong>over $700 million in sales. </strong></em>
                    She is ranked in the top half of 1% of all Berkshire Hathaway Home Services agents (which includes over 50,000 agents both nationwide and abroad) as well as the top 1.5% of 1.6 million agents in the nation by Real Trends.
                    Meredith has appeared in notable and local news outlets such as the Los Angeles Times, Business Insider, LA Confidential and The Wall Street Journal.
                </p>
                <p>
                    Navigating the waters of the highly competitive Los Angeles real estate market is no small task;
                    but since relocating from her native New York City, Meredith has made LA her own
                    She prides herself on a deep knowledge of LA's diverse neighborhoods, as well as the relationships she has built with clients and throughout the brokerage community.
                </p>
                <p>
                    As a native New Yorker, Meredith brings an East Coast mentality to the West coast vibe. Her persistence and follow-through is unyielding - <em><strong>she doesn't stop, doesn't quit and never shies away from rejection.</strong></em>
                    She goes to bat for her clients at every turn. Her laser-like attention to detail, preparation, and follow-through combined with an energetic, infectious personality make her sought after by home buyers and sellers alike. 
                    Meredith sets the bar high in sales, relationships, and client satisfaction. She believes that her clients should expect more out of their home buying and selling experience.
                </p>
                <p>
                    "My goal, first and foremost, is the relationship. It's not about just one transaction. 
                    It's about the big picture and being there for my clients to give my honest opinion - and even being there to turn them away if the deal isn't right," says Meredith. "I'm addicted to the process," she admits. "I love being the one to deliver the good news.
                     It's so fulfilling to know that I can make a difference both personally and financially in someone's life and help bring people closer to their goals."
                </p>
                <p>
                    When she is not out knocking on doors, making calls for her clients, or negotiating an offer, 
                    Meredith spends as much time as possible with her partner Randi raising their two small kids in Playa Vista - enjoying family time by the beach and navigating the waters of career/life balance. 
                    Meredith is also an avid tennis player and has a deep appreciation for travel and good food.
                </p>
                <p>
                    Meredith is involved in a number of charities that are near and dear to her heart. 
                    She donates a portion of her commissions to the Susan G. Komen breast cancer foundation, the Michael J. Fox Foundation, 
                    START Animal Rescue, and Dr. Mitch Seruya's Peripheral Nerve Program at Cedars-Sinai Medical Center.
                </p>

                <p>&nbsp;</p>
                <h1 class="title" align="center">About BHHS</h1>
                <br>
                <p style="text-align: center;"><a href="https://www.bhhscaproperties.com" target="_&quot;blank&quot;"><img src="/images/bhhs-logo.jpg" width="250" height="250"></a>
                    <br><br><a href="https://www.bhhscaproperties.com" target="_&quot;blank&quot;"><u>Visit the BHHS website</u></a>
                </p>
            </div>
        </div>
    </article>
</div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
    name: "About",
    components: {
        PageTitle,
    },
    data(){
        return{
        }
    },
    metaInfo: {
        title: 'About',
        titleTemplate: '%s Meredith Schlosser',
        meta: [
            {
                name: 'description',
                content: "About Meredith Schlosser - Real Estate Agent: Outgoing, Tireless, and a Commitment to Excellence. She's the Director of Aaroe International Luxury Properties and a member of the SFJ Group."
            },
            {
                name: 'author',
                content: "Meredith Schlosser"
            },
        ]
    }
}
</script>

<style lang="scss" scoped>

</style>